import React, { useState } from "react";
import '../styles/main.css';
import { useNavigate } from "react-router-dom";
import logo from '../assets/image.png'
import icon from '../assets/logo.png'
import microsoft from '../assets/microsoft2.png'
// import azure from '../assets/Azure.svg'
import axios from 'axios'

const Home = () => {
  // State to handle modals
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  // Functions to open and close modals
  const openLoginModal = () => setShowLoginModal(true);
  const closeLoginModal = () => setShowLoginModal(false);
  const openRegisterModal = () => setShowRegisterModal(true);
  const closeRegisterModal = () => setShowRegisterModal(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleGoogleSignIn = () => {
 
    console.log("Google Sign-In clicked");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [searchTerm, setSearchTerm] = useState('');

  // const handleSearch = () => {
  //   console.log("Search term:", searchTerm);
  //   Navigate(`/community?search=${encodeURIComponent(searchTerm)}`);
  // };
  const doSearch = (ev) => {
     ev.preventDefault();

    let data = {
      "searchwords": searchTerm
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://learninghub.mawingu.co/api/searchdata',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      const respoData = {
        resData: response.data, 
        quiz: searchTerm
      }
      navigate('/community', { state: { resData: respoData } });
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className="landing-page">
      <div className="div">
      
        <div className="top-nav">
          <div className="right">
          <div className="text-wrapper-8"> <img className="Logo" alt="Logo" src={icon} height='100px'  /></div>
            <button className="auth-button" onClick={openLoginModal}>
              <div className="text-wrapper-3">Login/Register</div>
            </button>
          </div>
        </div>

       {showLoginModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Login</h2>
            <input type="email" placeholder="Email" />
            <div className="password-input">
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Password"
              />
              <span
                className="eye-icon"
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer' }}
              >
                {passwordVisible ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="modal-buttons">
              <button className="button close-button" onClick={closeLoginModal}>
                Close
              </button>
              <button className="button submit-button">Login</button>
            </div>
          </div>
        </div>
      )}

      {showRegisterModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Register</h2>
            <input type="text" placeholder="Username" />
            <input type="email" placeholder="Email" />
            <div className="password-input">
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Password"
              />
              <span
                className="eye-icon"
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer' }}
              >
                {passwordVisible ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="modal-buttons">
              <button className="button close-button" onClick={closeRegisterModal}>
                Close
              </button>
              <button className="button submit-button">Register</button>
            </div>
          </div>
        </div>
      )}
       <div className="overlap-group">
        <p className="text-wrapper-7">Elimika na Mawingu </p> <br></br>
          <p className="modern-facilities">Learning Hub
          </p>  
          <div className="search-bar">
          <form onSubmit={doSearch}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"/>
          </form>
      </div>

      <div className="topcardsmain">
          <div className="bottom-buttons">
            <div className="div-wrapper1">
              <div className="text-wrapper-2">Education</div>
            </div>
              <div className="div-wrapper">
                <div className="text-wrapper-24">Health</div>
              </div>
              <div className="div-wrapper1">
                <div className="text-wrapper-2">Social</div>
              </div>
              <div className="div-wrapper">
                <div className="text-wrapper-24">Culture</div>
              </div>
              <div className="div-wrapper1">
                <div className="text-wrapper-2">Financial</div>
              </div>
              <div className="div-wrapper">
                <div className="text-wrapper-24">Legal</div>
              </div>

          </div>
          <div class="cards">
                <div className="frame">
                  <div className="frame-2">
                    <div className="text-wrapper-6">Pregnancy </div>
                  </div>
                  <p className="p">
                  Pregnancy is when a fetus develops inside a woman's uterus, 
                  usually after sexual intercourse, but can also occur through assisted reproductive technology.
                  </p>
                </div>
                    
                  <div className="frame">
                    <div className="frame-2">
                      <div className="text-wrapper-6">Cholera</div>
                    </div>
                    <p className="p">
                    Cholera is a severe diarrheal disease caused by the bacteria Vibrio cholerae. 
                    It's spread through contaminated food or water, and can cause life-threatening symptoms if left untreated. </p>
                  </div>
          </div>
        </div>
      
        </div>
      
   

      {/* <div className="topgroups">   */}
     
     
        <button className="button-2">
    <div className="text-wrapper-4">HOW IT WORKS</div>
</button>

       
        
     
       
        <div className="chat">
          <img className="chatbox" alt="Logo" src={logo} />

        </div>
         
        <div className="overlap23">
          <div className="bar">
            <p className="element-mawingu">
              <span className="span">© 2024</span>
              <span className="text-wrapper-9">&nbsp;</span>
              <span className="text-wrapper-10">Mawingu foundation </span>
              <span className="text-wrapper-9">. </span>
              <span className="text-wrapper-11">All rights reserved. </span>
            </p>

          <div className="microsoft">
          <img className="microsoft" alt="logo" src={microsoft} /> 


        </div>
            <div className="left">
              <div className="text-wrapper-12">Privacy</div>
              <div className="text-wrapper-12">Terms</div>
              <div className="text-wrapper-12">Settings</div>
            </div>
          </div>
          {/* <img className="LOGO" alt="Logo" src="/img/logo-2.png" /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
